import Player from './components/Player/Player'

import './App.scss'

const App = () => {

    console.log('new deploy')

    return (
        <div className="App">
            <Player />
        </div>
    )

}

export default App
