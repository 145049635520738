import React, { useEffect, useState, useRef, useContext } from 'react'
import { v4 as uuidv4 } from 'uuid'
import Hls from 'hls.js'
import axios from 'axios'
import plyr from 'plyr'
import 'plyr/dist/plyr.css'

import { RootContext } from '../../state/rootContext'
import { playerDefaults } from './playerDefaults'
import useEvent from '../../hooks/useEventHandler'
import time from '../../helpers/time'
import queryparams from '../../helpers/queryparams'
import doc from '../../helpers/doc'
import Loading from '../Loading/Loading'
import Error from '../Error/Error'

import './Player.scss'

const INTERVAL = 1000

const Player = () => {

    const player = useRef(null)
    
    const [state, dispatch] = useContext(RootContext)

    const [options, setOptions] = useState(null)
    const [sources, setSources] = useState(null)
    const [showCaptions, setShowCaptions] = useState(false)
    const [secSinceLastSent, setSecSinceLastSent] = useState(0)
    const [loadingError, setLoadingError] = useState(false)

    const fetchManifest = async () => {

        setOptions({
            controls: ['play-large', 'play', 'progress', 'current-time','duration','speed', 'volume', 'mute', 'captions', 'settings', 'fullscreen'],
            i18n: {
                buffered: "Buffered",
                captions: "Captions",
                currentTime: "Current time",
                disableCaptions: "Disable captions",
                download: "Download",
                duration: "Duration",
                enableCaptions: "Enable captions",
                enterFullscreen: "Enter fullscreen",
                exitFullscreen: "Exit fullscreen",
                fastForward: "Forward {seektime}s",
                frameTitle: "Player for {title}",
                loop: "Loop",
                menuBack: "Go back to previous menu",
                mute: "Mute",
                normal: "Normal",
                pause: "Pause",
                play: "Play",
                played: "Played",
                quality: "Quality",
                restart: "Restart",
                rewind: "Rewind {seektime}s",
                seek: "Seek",
                seekLabel: "{currentTime} of {duration}",
                settings: "Settings",
                speed: "Speed",
                unmute: "Unmute",
                volume: "Volume"
            },
            quality: {
                default: 720,
                options: [240, 360, 480, 720]
            }
        })

        setShowCaptions(true)

        setSources({
            type: "video",
            title: "Example Video",
            poster: "https://vod01-hbs73b283k-was.zmnet.net/hbs73b283k/pub/posters/b9fa04d7-4792-4c15-8ce6-7c956a8c92fd.jpg",
            sources: [{
                src: "https://vod01-hbs73b283k-was.zmnet.net/hbs73b283k/pub/b9fa04d7-4792-4c15-8ce6-7c956a8c92fd/master.m3u8",
                size: 720,
                type: "application/x-mpegURL"
            }]
        })

    }

    const loadPlayer = async () => {

        console.log('loading player')

        // if reloading the player
        if (player.current && player.current.config) player.current.destroy()
        player.current = new plyr('#plyr', options)

        const applePlatforms = [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ]

        const ipad13 = navigator.userAgent.includes("Mac") && "ontouchend" in document

        const isAppleDevice = player.current.media.canPlayType('application/vnd.apple.mpegurl') && (applePlatforms.includes(navigator.platform) || ipad13)        
        
        // some browsers care if you explicitly use the controls attribute and passing them into the html with react 
        // (eg, return <video id="plyr" controls/>) doesnt seem to work. so we need to inject them manually here
        if (!isAppleDevice) player.current.media.setAttribute("controls", true)

        // for apple devices - for some reason this is being caught 
        if (isAppleDevice) {

            console.log('player can play type application/vnd.apple.mpegurl (apple device)')
            const source = document.createElement('source')
            source.src = sources.sources[0].src
            source.type = 'application/vnd.apple.mpegURL'
            player.current.media.appendChild(source)
            console.log(player.current.media)
        }
        
        else if (Hls.isSupported()) {

            console.log('hls supported')

            try {

                const hls = new Hls()
                hls.loadSource(sources.sources[0].src)
                hls.attachMedia(player.current.media)
    
                // handle hls errors
                hls.on(Hls.Events.ERROR, (evt, err) => {

                    const { type, fatal } = err

                    console.log('hls error!')
                    console.log(type)

                    if (fatal) {
                        // try to recover network or media error
                        switch (type) {
                            case Hls.ErrorTypes.NETWORK_ERROR:
                                hls.startLoad()
                                break
                            case Hls.ErrorTypes.MEDIA_ERROR:
                                hls.recoverMediaError()
                                break
                            default:
                                // cannot recover
                                hls.destroy()
                                break
                        }
                    }

                })
    
                // ! log all hls events
                // Object.keys(Hls.Events).forEach(function (e) {
                //     hls.on(Hls.Events[e], console.info.bind(console))
                // })

            } catch (err) { console.log({err}) }

        }

        else console.log('hls not supported && not apple device')

        // set poster
        player.current.media.poster = sources.poster + `?cb=${Math.floor(Math.random() * 100) + 1}`

        // set captions
        showCaptions && sources.tracks && sources.tracks.forEach(({src, srclang, label}) => {

            const track = document.createElement('track')
            track.src = src
            track.srclang = srclang
            track.label = label
            track.kind = 'captions'
            player.current.media.appendChild(track)

        })

    }

    const setupPlayer = async () => {

        if (!loadingError && options && sources) {
            await loadPlayer()
            dispatch({type: 'SET_PLAYER_LOADED', payload: true})
        }

    }

    const generateControls = ({
        control_play_button,
        control_tracking, 
        control_volume, 
        control_captions, 
        control_fullscreen
    }) => {

        const controlsArr = []

        if (parseInt(control_play_button) !== 0) controlsArr.push('play-large')

        if (parseInt(control_tracking) !== 0) controlsArr.push('play', 'progress', 'current-time','duration','speed')

        if (parseInt(control_volume) !== 0) controlsArr.push('volume', 'mute')
        if (parseInt(control_captions) !== 0) {
            setShowCaptions(true)
            controlsArr.push('captions', 'settings')
        }

        if (parseInt(control_fullscreen) !== 0) controlsArr.push('fullscreen')

        return controlsArr

    }

    const handleIframeEvents = evt => {

        switch (evt.data.event) {

            case 'controls':
                const {element, state} = evt.data
                handleToggleControls({element, state})
                break

            case 'update_player_color':
                const {color}  = evt.data 
                handleUpdatePlayerColor({color})
                break

            case 'set_poster':
              const { poster } = evt.data
              handleSetPoster({poster}) 
              break 

            default:
                break

        }

    }

    const handleToggleControls = ({element, state}) => {

        let newControls

        // make changes to options
        switch (element) {

            case 'volume':
                newControls = generateControls({
                    control_play_button: options.controls.indexOf('play-large') > -1 ? 1 : 0, 
                    control_tracking: options.controls.indexOf('progress') > -1 ? 1 : 0, 
                    control_volume: state === 'on' ? 1 : 0, 
                    control_captions: options.controls.indexOf('captions') > -1 ? 1 : 0, 
                    control_fullscreen: options.controls.indexOf('fullscreen') > -1 ? 1 : 0
                })
                break

            case 'progress':
                newControls = generateControls({
                    control_play_button: options.controls.indexOf('play-large') > -1 ? 1 : 0, 
                    control_tracking: state === 'on' ? 1 : 0, 
                    control_volume: options.controls.indexOf('volume') > -1 ? 1 : 0, 
                    control_captions: options.controls.indexOf('captions') > -1 ? 1 : 0, 
                    control_fullscreen: options.controls.indexOf('fullscreen') > -1 ? 1 : 0
                })
                break

            case 'captions':
                newControls = generateControls({
                    control_play_button: options.controls.indexOf('play-large') > -1 ? 1 : 0, 
                    control_tracking: options.controls.indexOf('progress') > -1 ? 1 : 0,
                    control_volume: options.controls.indexOf('volume') > -1 ? 1 : 0, 
                    control_captions: state === 'on' ? 1 : 0, 
                    control_fullscreen: options.controls.indexOf('fullscreen') > -1 ? 1 : 0
                })
                break

            case 'fullscreen':
                newControls = generateControls({
                    control_play_button: options.controls.indexOf('play-large') > -1 ? 1 : 0, 
                    control_tracking: options.controls.indexOf('progress') > -1 ? 1 : 0,
                    control_volume: options.controls.indexOf('volume') > -1 ? 1 : 0, 
                    control_captions: options.controls.indexOf('captions') > -1 ? 1 : 0, 
                    control_fullscreen:state === 'on' ? 1 : 0
                })
                break

            case 'play':
                newControls = generateControls({
                    control_play_button: state === 'on' ? 1 : 0,
                    control_tracking: options.controls.indexOf('progress') > -1 ? 1 : 0,
                    control_volume: options.controls.indexOf('volume') > -1 ? 1 : 0, 
                    control_captions: options.controls.indexOf('captions') > -1 ? 1 : 0, 
                    control_fullscreen: options.controls.indexOf('fullscreen') > -1 ? 1 : 0
                })
                break
                
            default:
                return
        }

        setOptions(options => ({...options, controls: newControls}))

    }

    const handleUpdatePlayerColor = ({color}) => {

        if (color) document.documentElement.style.setProperty('--plyr-color-main', color)

        // reload player (since we are not updating options or sources state, the useEffect that automatically loads the player will not run)
        if (player.current && player.current.media) loadPlayer()

    }

    const handleSetPoster = ({poster}) => {

        setSources({
            ...sources, 
            poster: poster + `?cb=${Math.floor(Math.random() * 100) + 1}`
        })

    }

    const togglePlaying = () => player.current.togglePlay()

    const handleMobileOverlayClicked = () => {

        if (player.current) togglePlaying()

    }

    // bind window.addEventListener w/ cleanup each render
    useEvent('message', evt => handleIframeEvents(evt))

    // fetch manifest + player settings and set the options and sources state
    useEffect(() => fetchManifest(), [])

    // after manifest, captions and other player settings are loaded, load the player
    useEffect(() => setupPlayer(), [options, sources, loadingError])

    const isTouchDevice = doc.determineIfTouchDevice()

    if (options && sources) {
        return (
            <>
                <video ref={player} id="plyr" crossOrigin="true" playsInline controls />
                {/* this mobile overlay allows single press gestures to pause the player on mobile */}
                <div id="mobileOverlay" className={isTouchDevice ? '' : 'd-none'} onClick={handleMobileOverlayClicked} />
            </>

        )
    }
    else return <Loading />
}

export default Player